//utils
import { createAction as createReduxAction } from "Utils/Redux";

const createAction = <T extends string, P = void>(type: T) => createReduxAction<
	T, P, "TEST_MODULE"
>(type, "TEST_MODULE");

/*const createRoutine = <
	T extends string,
	TriggerPayload = void,
	RequestPayload = void,
	SuccessPayload = void,
	FailurePayload = void,
	FulfillPayload = void
>(type: T) => createReduxRoutine<
	T,
	TriggerPayload,
	RequestPayload,
	SuccessPayload,
	FailurePayload,
	FulfillPayload,
	"PANEL"
>(type, "PANEL");*/

export default {
	INITIATE: createAction("INITIATE"),
	UNINITIATE: createAction("UNINITIATE"),
	TEST_ACTION: createAction<"TEST_ACTION", string>("TEST_ACTION"),
};
