import React, { FC, useState, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

//components
import Navbar from "Components/Navbar";
import LoginSite from "Containers/Auth/UI/LoginSite/LoginSite";

//constants
import { CONFIG_MAP } from "Constants/Config";

//Counter
import Counter from "Components/Counter/Counter";
import Counter2 from "Components/Counter2/Counter2";

//hooks
import useGetConfig from "Hooks/General/useGetConfig";

//styles
import { MainAppWrapper } from "Components/styles/MainApp.styles";

const MainApp: FC = () => {
	//const isAuthenticated = useSelector(AuthSelectors.getIsAuthenticated);
	const config = useGetConfig();
	const isAuthenticated = config.isAuthenticated;
	console.log("isAuthenticated", isAuthenticated);

	const [marginTop, setMarginTop] = useState("7vh");

	useEffect(() => {
		const checkViewportSizes = () => {
			const vw = window.innerWidth * 0.04;
			const vh = window.innerHeight * 0.07;
			setMarginTop(vw > vh ? "4vw" : "7vh");
		};

		checkViewportSizes();
		window.addEventListener("resize", checkViewportSizes);

		return () => window.removeEventListener("resize", checkViewportSizes);
	}, []);

	const routes = isAuthenticated
		? [
			<Route path="/Counter" element={<Counter/>} key="Counter" />,
			<Route path="/Counter2" element={<Counter2/>} key="Counter2" />,
			<Route path="/Login" element={<LoginSite/>} key="Login" />,
		]
		: [
			<Route path="/" element={<LoginSite/>} key="Login" />,
			<Route path="/Counter" element={<LoginSite/>} key="Login" />,
			<Route path="/Counter2" element={<LoginSite/>} key="Login" />,
			<Route path="/Login" element={<LoginSite/>} key="Login" />,
		];

	return (
		<>
			<Navbar />
			<MainAppWrapper marginTop={marginTop}>
				<Routes>
					{routes}
				</Routes>
			</MainAppWrapper>
		</>
	);
};

export default React.memo(MainApp);
