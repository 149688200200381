export function createAction<
	T extends string,
	P = void,
	//S extends string | void = void
	S extends string | undefined = undefined
>(type: T, prefix?: S) {
	const typeWithPrefix = `${prefix ? `${prefix}/` : ""}${type}` as S extends string ? `${S}/${T}` : T;
	return {
		ACTION: typeWithPrefix,
		action: (payload: P) => ({ type: typeWithPrefix, payload }),
	};
}

export function createRoutine<
	T extends string,
	TriggerPayload = void,
	RequestPayload = void,
	SuccessPayload = void,
	FailurePayload = void,
	FulfillPayload = void,
	//S extends string | void = void,
	S extends string | undefined = undefined,
	>(type: T, prefix?: S) {
	const typeWithPrefix = `${prefix ? `${prefix}/` : ""}${type}` as S extends string ? `${S}/${T}` : T;
	const triggerActionType = `${typeWithPrefix}/TRIGGER` as const;
	const requestActionType = `${typeWithPrefix}/REQUEST` as const;
	const successActionType = `${typeWithPrefix}/SUCCESS` as const;
	const failureActionType = `${typeWithPrefix}/FAILURE` as const;
	const fulfillActionType = `${typeWithPrefix}/FULFILL` as const;

	return {
		TRIGGER: triggerActionType,
		REQUEST: requestActionType,
		SUCCESS: successActionType,
		FAILURE: failureActionType,
		FULFILL: fulfillActionType,
		trigger: (payload: TriggerPayload) => ({ type: triggerActionType, payload }),
		request: (payload: RequestPayload) => ({ type: requestActionType, payload }),
		success: (payload: SuccessPayload) => ({ type: successActionType, payload }),
		failure: (payload: FailurePayload) => ({ type: failureActionType, payload }),
		fulfill: (payload: FulfillPayload) => ({ type: fulfillActionType, payload }),
		toString: () => typeWithPrefix,
	};
}
