//types
import { AppState } from "Redux/definitions/AppState";
import { defaultState, StateTestModule2 } from "./TestModule2.reducer";

type CompleteState = AppState & { TestModule2: StateTestModule2 };

export const getTestModule2 = (state: CompleteState) => state.TestModule2 || defaultState;

export const getTestModule2TestString = (state: CompleteState) => getTestModule2(state).testString2;

export default {
	getTestString: getTestModule2TestString,
};
