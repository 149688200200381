import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

//actions
import RootActions from "Redux/Root.actions";

//hooks
import useGetConfig from "Hooks/General/useGetConfig";

const ConfigTracker: FC = () => {
	const dispatch = useDispatch();
	const config = useGetConfig();
	console.log("Tracker - config", config);

	useEffect(() => {
		dispatch(RootActions.UPDATE_CONFIG.action(config));
	}, [config]);

	return null;
};

export default ConfigTracker;
