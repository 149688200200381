//actions
import TestModuleActions from "./TestModule.actions";

export interface StateTestModule {
	testString: string | null;
}

export const defaultState: StateTestModule = {
	testString: "startValue",
};

type TestModuleActionTypes = ReturnType<typeof TestModuleActions.TEST_ACTION.action>;

export default (
	state: StateTestModule = defaultState,
	action: TestModuleActionTypes,
): StateTestModule => {
	switch (action.type) {
		case TestModuleActions.TEST_ACTION.ACTION:
			return {
				...state,
				testString: action.payload,
			};

		default:
			return state;
	}
};
