//import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//constants
import { CONFIG_MAP } from "Constants/Config";

//selectors
import TestModuleSelectors from "Containers/TestModule/TestModule.selectors";

export default (): Record<keyof typeof CONFIG_MAP | "isAuthenticated", boolean> => {
	const testString = useSelector(TestModuleSelectors.getTestString);
	console.log("useGetConfig - testString", testString);

	const isTestModuleConfigured = true; //TEMP
	const is2ndTestModuleConfigured = testString === "updatedString";

	const isAuthenticated = true; //TEMP

	const config = {
		[CONFIG_MAP.testModule2]: is2ndTestModuleConfigured,
		[CONFIG_MAP.testModule]: isTestModuleConfigured,
		isAuthenticated,
	};
	return config;
};
