//types
import { AppState } from "Redux/definitions/AppState";
import { defaultState, StateTestModule} from "./TestModule.reducer";

type CompleteState = AppState & { TestModule: StateTestModule };

export const getTestModule = (state: CompleteState) => state.TestModule || defaultState;

export const getTestModuleTestString = (state: CompleteState) => getTestModule(state).testString;

export default {
	getTestString: getTestModuleTestString,
};
