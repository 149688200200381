import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import {
	BrowserRouter as Router,
} from "react-router-dom";
import MainApp from "Components/MainApp";

//actions
import RootActions from 'Redux/Root.actions';

//components
import ConfigTracker from "Components/ConfigTracker";

function App(): JSX.Element {
	//const dispatch = useDispatch();

	/*useEffect(() => {
		dispatch(RootActions.FETCH_CONFIG.action());
	}, [dispatch]);*/

	return (
	<Router>
		<MainApp />
		<ConfigTracker />
	</Router>
	);
}

export default App;
