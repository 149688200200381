import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { store, initializeStore } from './Redux/store';

//Cli
import Cli from "Utils/Cli";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV === 'development') {
	window.app = new Cli(store);
}

initializeStore()
	.then(() => {
		root.render(
			<React.StrictMode>
				<Provider store={store}>
					<App />
				</Provider>
			</React.StrictMode>,
		);
	})
	.catch((error) => {
		console.error("Error initializing store: ", error);
	});

