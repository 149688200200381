import { styled } from "@mui/material";

interface MainAppWrapperProps {
	marginTop: string;
}

export const MainAppWrapper = styled("div")<MainAppWrapperProps>(({ marginTop }) => ({
	height: `calc(100vh - ${marginTop})`,
	marginTop,
}));
