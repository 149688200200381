//actions
import RootActions from "./Root.actions";

export interface StateRoot {
	config: Record<string, boolean>;
}

export const defaultState: StateRoot = {
	config: {},
};

type TestModuleActionTypes = ReturnType<typeof RootActions.SET_CONFIG.action>;

export default (
	state: StateRoot = defaultState,
	action: TestModuleActionTypes,
): StateRoot => {
	switch (action.type) {
		case RootActions.SET_CONFIG.ACTION:
			return {
				...state,
				config: action.payload,
			};

		default:
			return state;
	}
};
