//actions
import TestModuleActions from "./TestModule.actions";

//constants
import { CONFIG_MAP } from "Constants/Config";

//reducer
import TestModuleReducer from "./TestModule.reducer";

//sagas
import TestModuleSagas from "./TestModule.sagas";

//functions
//import { initFunctions } from "./Panel.functions";

export const initializeTestModule = (config: Record<keyof typeof CONFIG_MAP, boolean>) => {
	const modules: any = [];

	if (config[CONFIG_MAP.testModule]) {
		modules.push({
			id: "TestModule",
			reducerMap: {
				TestModule: TestModuleReducer,
			},
			sagas: [TestModuleSagas],
			initialActions: [TestModuleActions.INITIATE.action()],
			finalActions: [TestModuleActions.UNINITIATE.action()],
		});
	}

	return modules;
};
