//actions
import TestModule2Actions from "./TestModule2.actions";

//constants
import { CONFIG_MAP } from "Constants/Config";

//reducer
import TestModule2Reducer from "./TestModule2.reducer";

//sagas
import TestModule2Sagas from "./TestModule2.sagas";

//functions
//import { initFunctions } from "./Panel.functions";

export const initializeTestModule2 = (config: Record<keyof typeof CONFIG_MAP, boolean>) => {
	const modules: any = [];

	if (config[CONFIG_MAP.testModule2]) {
		modules.push({
			id: "TestModule2",
			reducerMap: {
				TestModule2: TestModule2Reducer,
			},
			sagas: [TestModule2Sagas],
			initialActions: [TestModule2Actions.INITIATE.action()],
			finalActions: [TestModule2Actions.UNINITIATE.action()],
		});
	}

	return modules;
};
