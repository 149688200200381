import { put, takeLatest, select, delay } from "redux-saga/effects";

//actions
import TestModule2Actions from "Containers/TestModule2/TestModule2.actions";

//selectors
import TestModule2Selectors from "Containers/TestModule2/TestModule2.selectors";

function* onInitiate() {
	console.log("onInitiate");
	const testString: ReturnType<typeof TestModule2Selectors.getTestString> = yield select(TestModule2Selectors.getTestString);
	console.log("testString2", testString);
	yield put(TestModule2Actions.TEST_ACTION.action("updatedStringTESTMODULE2"));
}

function* onUninitiate() {
	console.log("onUninitiate");
	//yield call(SynchronizationFunctions.RemoveEventListener, "PRODUCTS", "UpdatePayOutList");
	//yield call(SynchronizationFunctions.RemoveEventListener, "POS_CONFIG", "UpdatePayOutListPosConfig");
}

function* onTestActions() {
	const testString: ReturnType<typeof TestModule2Selectors.getTestString> = yield select(TestModule2Selectors.getTestString);
	console.log("testString2", testString);
}

export default function* rootSagas() {
	yield takeLatest(TestModule2Actions.INITIATE.ACTION, onInitiate);
	yield takeLatest(TestModule2Actions.UNINITIATE.ACTION, onUninitiate);
	yield takeLatest(TestModule2Actions.TEST_ACTION.ACTION, onTestActions);
}
