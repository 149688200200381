import { takeLatest, put, call, select, delay } from "redux-saga/effects";
import { AppState } from "Redux/definitions/AppState";

//actions
import RootActions from "./Root.actions";

//config
import { getConfigAsync } from "Utils/getConfigAsync";

import { initializeStore } from "./store";

function* onUpdateConfig({ payload }: ReturnType<typeof RootActions.UPDATE_CONFIG.action>) {
	const config = payload;
	yield put(RootActions.SET_CONFIG.action(config));
	yield call(initializeStore);
}

export default function* rootSagas() {
	yield takeLatest(RootActions.UPDATE_CONFIG.ACTION, onUpdateConfig);
}
