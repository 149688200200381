//actions
import TestModule2Actions from "./TestModule2.actions";

export interface StateTestModule2 {
	testString2: string | null;
}

export const defaultState: StateTestModule2 = {
	testString2: "startValue",
};

type TestModuleActionTypes = ReturnType<typeof TestModule2Actions.TEST_ACTION.action>;

export default (
	state: StateTestModule2 = defaultState,
	action: TestModuleActionTypes,
): StateTestModule2 => {
	switch (action.type) {
		case TestModule2Actions.TEST_ACTION.ACTION:
			return {
				...state,
				testString2: action.payload,
			};

		default:
			return state;
	}
};
