import { put, takeLatest, select, delay } from "redux-saga/effects";

//actions
import TestModuleActions from "Containers/TestModule/TestModule.actions";

//selectors
import TestModuleSelectors from "Containers/TestModule/TestModule.selectors";

function* onInitiate() {
	console.log("onInitiate");
	const testString: ReturnType<typeof TestModuleSelectors.getTestString> = yield select(TestModuleSelectors.getTestString);
	console.log("testString", testString);
	yield delay(10000);
	yield put(TestModuleActions.TEST_ACTION.action("updatedString"));
	yield delay(15000);
	yield put(TestModuleActions.TEST_ACTION.action("updatedString2"));
}

function* onUninitiate() {
	console.log("onUninitiate");
	//yield call(SynchronizationFunctions.RemoveEventListener, "PRODUCTS", "UpdatePayOutList");
	//yield call(SynchronizationFunctions.RemoveEventListener, "POS_CONFIG", "UpdatePayOutListPosConfig");
}

function* onTestActions() {
	const testString: ReturnType<typeof TestModuleSelectors.getTestString> = yield select(TestModuleSelectors.getTestString);
	console.log("testString", testString);
}

export default function* rootSagas() {
	yield takeLatest(TestModuleActions.INITIATE.ACTION, onInitiate);
	yield takeLatest(TestModuleActions.UNINITIATE.ACTION, onUninitiate);
	yield takeLatest(TestModuleActions.TEST_ACTION.ACTION, onTestActions);
}
