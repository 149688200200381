import { styled } from "@mui/material";

//components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

export const StyledAppBar = styled(AppBar)(() => ({
	height: "7vh",
	minHeight: "4vw",
	position: "fixed",
}));

export const StyledToolbar = styled(Toolbar)(() => ({
	height: "7vh",
	minHeight: "4vw !important",
}));