import React, { FC, FormEvent, useState } from 'react';
import HmacSHA256 from 'crypto-js/hmac-sha256';


interface ICounterProps {
  value?: number;
  onIncrement?: () => void;
  onDecrement?: () => void;
  onIncrementAsync?: () => void;
  onDecrementAsync?: () => void;
}
const Counter: FC<ICounterProps> = ({
  value,
  onIncrement,
  onDecrement,
  onIncrementAsync,
  onDecrementAsync,
}): JSX.Element => {
	const [testValue, setTestValue] = useState("");

	const _onSubmitButton = () => {
		console.log("testValue", testValue);
		/*const hash = sha256(testValue);
		console.log("hash", hash.toString());*/
		//hash the string using a secret key
		const hash = HmacSHA256(testValue, "secret key");
		//console.log("hash", hash.toString());
		console.log("hash", hash.toString());
	}

  return (
    <div>
      <button onClick={onIncrementAsync} className="button">
		<input
			onSubmit={_onSubmitButton}
			onChange={(e) => setTestValue(e.target.value)}
			value={testValue}
		>

		</input>
        Increment after 1 second
      </button>{' '}
      <button onClick={_onSubmitButton} className="button">
        submit form
      </button>{' '}
      <button onClick={onIncrement} className="button">
        + Increment
      </button>{' '}
      <button onClick={onDecrement} className="button">
        - Decrement
      </button>
      <hr />
      <div>Clicked: {value} times</div>
    </div>
  );
};

export default Counter;
