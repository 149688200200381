import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//styles
import { StyledAppBar, StyledToolbar } from "Components/styles/Navbar.styles";

const NavBar: FC = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: any) => {
	setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
	setAnchorEl(null);
	};

	return (
	<StyledAppBar>
		<StyledToolbar>
			<IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
				<MenuIcon 
					aria-label="menu"
					aria-controls="basic-menu"
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				/>
			</IconButton>
			<Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}>
				My App
			</Typography>
			<Button color="inherit" component={Link} to="/Login">Login</Button>
			{/*Dropdown Menu*/}
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
				'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem onClick={handleClose} component={Link} to="/Login">Login</MenuItem>
				<MenuItem onClick={handleClose} component={Link} to="/Counter">Counter</MenuItem>
				<MenuItem onClick={handleClose} component={Link} to="/page3">Page 3</MenuItem>
			</Menu>
		</StyledToolbar>
	</StyledAppBar>
	);
};

export default NavBar;